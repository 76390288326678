<template>
  <el-dialog
      :title="params.title"
      :model-value="params.show"
      @close="onCacel"
      width="800px" center>
    <el-form label-width="80px" class="demo-ruleForm">
      <el-form-item label="标题" prop="title">
        <el-input :modelValue="params.data.message" readonly></el-input>
      </el-form-item>

      <el-form-item label="内容" prop="content" style="grid-column: 1 / 3;">
        <el-input type="textarea" :rows="20" :modelValue="params.data.detail"  readonly></el-input>
      </el-form-item>

      <el-form-item>
        <el-button @click="onCacel()">返回</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>

export default {
  name: "Form",
  // 继承父窗体列表页的数据
  props: {
    params: Object
  },
  data() {
    return {
      // 全局变量，用于获得配置信息等
      global: window.global,

      // 更多选项
      option: {

      },
      // 验证选项
      rules: {},
    };
  },
  // 父组件中继承过来的事件
  emits: ["onClose","getTableData"],
  methods: {
    // 返回事件
    onCacel() {
      this.$emit('onClose')
    }
  }
}
</script>